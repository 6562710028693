<template>
    <v-container fluid>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Título</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="rule.title" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

         <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Puntaje</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="rule.score" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Descripción</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-textarea label="" v-model="rule.description" required outlined dense rows="12" row-height="15">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

// import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import mixins from "@/mixins";


export default {
    name: "form-rule",
    props: ["rule"],
    watch: {},
    validations() {
        return {
            rule: {
                title: required,
            },
        };
    },
    data: () => ({
    }),
    computed: {
        // ...mapState({
        //     selectTeams: (state) => state.team.selectTeams,
        // }),
    },
    methods: {
        // ...mapActions(["fetchAllTeams"]),
        getFetch() {
            // this.fetchAllTeams();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>