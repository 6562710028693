<template>
  <v-container fluid>
    <v-col col="12" md="12" lg="12" outlined>
      <v-card class="pa-4 rounded-xl">
        <v-card-title class="justify-left d-flex flex-row pb-1">
          <p>Puntaje</p>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider color="#75D89F" class="mx-3"></v-divider>
        <v-card-text>
          <!-- <h3 style="color:#083C5F" class="pb-4">Información del Grupo</h3> -->
          <v-row>
            <v-col col="12">
              <form-rule :rule="irule"></form-rule>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-btn @click="save()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
              Grabar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>


<script>
import FormRule from "@/components/forms/FormRule.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: "RuleAddEdit",
  props: ["id"],
  components: {
    FormRule,
  },
  watch: {
    rule: function (value) {
      // console.log(value)
      if (this.id) {
        this.irule = _cloneDeep(value);
      } 
    },
    ruleError: function (value) {
      if (value)
        this.mensaje(true, "error", this.ruleError.message, 5000);
    },
    ruleSusccesMessage: function (value) {
      if (value) {
        this.mensaje(true, "success", this.ruleSusccesMessage, 5000);
        if (!this.id) this.irule = {};
        this.$router.go(-1);
      }
    },

  },
  computed: {
    ...mapState({
      rule: (state) => state.rule.rule,
      ruleError: (state) => state.rule.ruleError,
      ruleSusccesMessage: (state) => state.rule.ruleSusccesMessage,
    }),
  },
  data: () => ({
    irule: {
    },
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["editRule", "getRuleById"]),
    save() {
      // console.log(this.irule);
      if (this.id) {
        //update
        this.editRule({ rule: this.irule });
      } else {
        this.addRule({ rule: this.irule });
        //create
      }
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      // console.log(this.rule)
      if (this.id) {
        this.getRuleById({ id: this.id });
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
</style>